<template>
  <PageTemplate>
		<Grid>
			<GridContainer size="12">
				<Heading size="3">Color</Heading>
			</GridContainer>
			<GridContainer size="12 9@md">
				<Card>
					<CardSection>
						<Heading size="4">Brand Colors</Heading>
					</CardSection>
					<CardSection>
						<Table>
							<TableRow head>
								<TableCell>
									<Paragraph margin="none" bold>Name</Paragraph>
								</TableCell>
								<TableCell>
									<Paragraph margin="none" bold>Example</Paragraph>
								</TableCell>
								<TableCell>
									<Paragraph margin="none" bold>Design Token</Paragraph>
								</TableCell>
								<TableCell>
									<Paragraph margin="none" bold>Hex</Paragraph>
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>
									<Paragraph margin="none" bold>Brand Primary</Paragraph>
								</TableCell>
								<TableCell>
									<Stage>
										<div class="color-sample color-brand-primary"></div>
									</Stage>
								</TableCell>
								<TableCell>
									<Paragraph margin="none">$brand-primary</Paragraph>
								</TableCell>
								<TableCell>
									<Paragraph margin="none">
										<Stage type="AR">
											#cebabb
										</Stage>
										<Stage type="DL">
											#4ad7d1
										</Stage>
										<Stage type="ED">
											#4154f1
										</Stage>
										<Stage type="HV">
											#ef106e
										</Stage>
										<Stage type="SD">
											#d3784a
										</Stage>
										<Stage type="WM">
											#5e9e95
										</Stage>
										<Stage type="DS">
											#1c84ee
										</Stage>
									</Paragraph>
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>
									<Paragraph margin="none" bold>Brand Secondary</Paragraph>
								</TableCell>
								<TableCell>
									<Stage>
										<div class="color-sample color-brand-secondary"></div>
									</Stage>
								</TableCell>
								<TableCell>
									<Paragraph margin="none">$brand-secondary</Paragraph>
								</TableCell>
								<TableCell>
									<Paragraph margin="none">
										<Stage type="AR">
											#4f4f4f
										</Stage>
										<Stage type="DL">
											#fe4a49
										</Stage>
										<Stage type="ED">
											#1FC07D
										</Stage>
										<Stage type="HV">
											#ffd800
										</Stage>
										<Stage type="SD">
											#e5e1d7
										</Stage>
										<Stage type="WM">
											#31645e
										</Stage>
										<Stage type="DS">
											#6f42c1
										</Stage>
									</Paragraph>
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>
									<Paragraph margin="none" bold>Brand Tertiary</Paragraph>
								</TableCell>
								<TableCell>
									<Theme ar dl ed sd wm ds>
										<Stage>
											<div class="color-sample color-brand-tertiary"></div>
										</Stage>
									</Theme>
									<Theme hv>
										<Paragraph margin="none">-</Paragraph>
									</Theme>
								</TableCell>
								<TableCell>
									<Theme ar dl ed sd wm ds>
										<Paragraph margin="none">$brand-tertiary</Paragraph>
									</Theme>
									<Theme hv>
										<Paragraph margin="none">-</Paragraph>
									</Theme>
								</TableCell>
								<TableCell>
									<Paragraph margin="none">
										<Stage type="AR">
											#9F6F73
										</Stage>
										<Stage type="DL">
											#001730
										</Stage>
										<Stage type="ED">
											#E1A026
										</Stage>
										<Stage type="HV">
											-
										</Stage>
										<Stage type="SD">
											#e3ac8c
										</Stage>
										<Stage type="WM">
											#e1e1e1
										</Stage>
										<Stage type="DS">
											#fa5f1c
										</Stage>
									</Paragraph>
								</TableCell>
							</TableRow>
						</Table>
					</CardSection>
				</Card>
			</GridContainer>
			<GridContainer size="12 9@md">
				<Card>
					<CardSection>
						<Heading size="4">UI Colors</Heading>
					</CardSection>
					<CardSection>
						<Table>
							<TableRow head>
								<TableCell>
									<Paragraph margin="none" bold>Name</Paragraph>
								</TableCell>
								<TableCell>
									<Paragraph margin="none" bold>Example</Paragraph>
								</TableCell>
								<TableCell>
									<Paragraph margin="none" bold>Design Token</Paragraph>
								</TableCell>
								<TableCell>
									<Paragraph margin="none" bold>Hex</Paragraph>
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>
									<Paragraph margin="none" bold>UI Dark</Paragraph>
								</TableCell>
								<TableCell>
									<Stage>
										<div class="color-sample color-ui-dark"></div>
									</Stage>
								</TableCell>
								<TableCell>
									<Paragraph margin="none">$ui-dark</Paragraph>
								</TableCell>
								<TableCell>
									<Paragraph margin="none">
										<Stage type="AR">
											#000000
										</Stage>
										<Stage type="DL">
											#000000
										</Stage>
										<Stage type="ED">
											#232323
										</Stage>
										<Stage type="HV">
											#000000
										</Stage>
										<Stage type="SD">
											#234a53
										</Stage>
										<Stage type="WM">
											#222222
										</Stage>
										<Stage type="DS">
											#495057
										</Stage>
									</Paragraph>
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>
									<Paragraph margin="none" bold>UI Light</Paragraph>
								</TableCell>
								<TableCell>
									<Stage>
										<div class="color-sample color-ui-light"></div>
									</Stage>
								</TableCell>
								<TableCell>
									<Paragraph margin="none">$ui-light</Paragraph>
								</TableCell>
								<TableCell>
									<Paragraph margin="none">
										<Stage type="AR">
											#e7e5e8
										</Stage>
										<Stage type="DL">
											#ffffff
										</Stage>
										<Stage type="ED">
											#ffffff
										</Stage>
										<Stage type="HV">
											#ffffff
										</Stage>
										<Stage type="SD">
											#ffffff
										</Stage>
										<Stage type="WM">
											#ffffff
										</Stage>
										<Stage type="DS">
											#ffffff
										</Stage>
									</Paragraph>
								</TableCell>
							</TableRow>
						</Table>
					</CardSection>
				</Card>
			</GridContainer>
			<GridContainer size="12 9@md">
				<Card>
					<CardSection>
						<Heading size="4">System Colors</Heading>
					</CardSection>
					<CardSection>
						<Table>
							<TableRow head>
								<TableCell>
									<Paragraph margin="none" bold>Name</Paragraph>
								</TableCell>
								<TableCell>
									<Paragraph margin="none" bold>Example</Paragraph>
								</TableCell>
								<TableCell>
									<Paragraph margin="none" bold>Design Token</Paragraph>
								</TableCell>
								<TableCell>
									<Paragraph margin="none" bold>Hex</Paragraph>
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>
									<Paragraph margin="none" bold>System Info</Paragraph>
								</TableCell>
								<TableCell>
									<Stage>
										<div class="color-sample color-system-info"></div>
									</Stage>
								</TableCell>
								<TableCell>
									<Paragraph margin="none">$system-info</Paragraph>
								</TableCell>
								<TableCell>
									<Paragraph margin="none">
										<Stage type="AR">
											#404994
										</Stage>
										<Stage type="DL">
											#276cbb
										</Stage>
										<Stage type="ED">
											#4154f1
										</Stage>
										<Stage type="HV">
											#3bb4ff
										</Stage>
										<Stage type="SD">
											#234aa1
										</Stage>
										<Stage type="WM">
											#5777bb
										</Stage>
										<Stage type="DS">
											#16daf1
										</Stage>
									</Paragraph>
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>
									<Paragraph margin="none" bold>System Success</Paragraph>
								</TableCell>
								<TableCell>
									<Stage>
										<div class="color-sample color-system-success"></div>
									</Stage>
								</TableCell>
								<TableCell>
									<Paragraph margin="none">$system-success</Paragraph>
								</TableCell>
								<TableCell>
									<Paragraph margin="none">
										<Stage type="AR">
											#307260
										</Stage>
										<Stage type="DL">
											#26976e
										</Stage>
										<Stage type="ED">
											#1FC07D
										</Stage>
										<Stage type="HV">
											#0ae64c
										</Stage>
										<Stage type="SD">
											#227526
										</Stage>
										<Stage type="WM">
											#568558
										</Stage>
										<Stage type="DS">
											#34c38f
										</Stage>
									</Paragraph>
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>
									<Paragraph margin="none" bold>System Warning</Paragraph>
								</TableCell>
								<TableCell>
									<Stage>
										<div class="color-sample color-system-warning"></div>
									</Stage>
								</TableCell>
								<TableCell>
									<Paragraph margin="none">$system-warning</Paragraph>
								</TableCell>
								<TableCell>
									<Paragraph margin="none">
										<Stage type="AR">
											#c0b35c
										</Stage>
										<Stage type="DL">
											#ffcc5a
										</Stage>
										<Stage type="ED">
											#E1A026
										</Stage>
										<Stage type="HV">
											#e3e63d
										</Stage>
										<Stage type="SD">
											#d3af61
										</Stage>
										<Stage type="WM">
											#afa080
										</Stage>
										<Stage type="DS">
											#ffcc5a
										</Stage>
									</Paragraph>
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>
									<Paragraph margin="none" bold>System Danger</Paragraph>
								</TableCell>
								<TableCell>
									<Stage>
										<div class="color-sample color-system-danger"></div>
									</Stage>
								</TableCell>
								<TableCell>
									<Paragraph margin="none">$system-danger</Paragraph>
								</TableCell>
								<TableCell>
									<Paragraph margin="none">
										<Stage type="AR">
											#83484c
										</Stage>
										<Stage type="DL">
											#c73939
										</Stage>
										<Stage type="ED">
											#cc2934
										</Stage>
										<Stage type="HV">
											#e0000f
										</Stage>
										<Stage type="SD">
											#cc3b3b
										</Stage>
										<Stage type="WM">
											#9b6363
										</Stage>
										<Stage type="DS">
											#ef6767
										</Stage>
									</Paragraph>
								</TableCell>
							</TableRow>
						</Table>
					</CardSection>
				</Card>
			</GridContainer>
		</Grid>
	</PageTemplate>
</template>

<script>
import PageTemplate from '@/views/designsystem/template/Page'; 
export default {
  components: {
		PageTemplate
  },
  data() {
    return {
      theme: ''
		}
  },
  mounted() {
		setTimeout(() =>{ 
			this.stageTheme(this.theme);
		}, 30);
  },
}
</script>

<style lang="scss" scoped>
.color-sample{
	height: 20px;
	width: 60px;
}
.color-brand-primary{
	background: var(--color-brand-primary);
}
.color-brand-secondary{
	background: var(--color-brand-secondary);
}
.color-brand-tertiary{
	background: var(--color-brand-tertiary);
}

.color-ui-light{
	background: var(--color-ui-light);
	border: 1px solid #e7e9eb;
}
.color-ui-dark{
	background: var(--color-ui-dark);
}

.color-system-info{
	background: var(--color-system-info);
}
.color-system-success{
	background: var(--color-system-success);
}
.color-system-warning{
	background: var(--color-system-warning);
}
.color-system-danger{
	background: var(--color-system-danger);
}


</style>
